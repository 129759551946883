export default function notifications(
  initialState = {
    toastReff: null
  },
  action
) {
  switch (action.type) {
    case 'SET_NOTIFICATIONS_TAOST_REF':
      return {
        ...initialState,
        toastReff: action.payload
      };

    case 'NOTIFICATIONS_RESET':
      return {
        ...initialState,
        toastReff: null
      };

    default:
      return initialState;
  }
}
