export const trimObjectValues = (obj, excludedKey = '') => {
  // Add excludedKey parameter
  for (let key in obj) {
    if (key !== excludedKey) {
      // Skip excludedKey
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].trim();
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        trimObjectValues(obj[key], excludedKey); // Recursively trim values of nested objects
      }
    }
  }
};

export const isFormFieldValid = (formik, name) =>
  !!(formik?.touched?.[name] && formik?.errors?.[name]);

export const getFormErrorMessage = (formik, name) => {
  return (
    isFormFieldValid(formik, name) && <small className="p-error">{formik?.errors?.[name]}</small>
  );
};
