export const LABELS = {
  WELCOME: 'Welcome to IMS portal!',
  KEEP_ME_SIGNED_IN: 'Keep me signed in',
  POWERED_BY: 'Powered by',
  FORGOT_PASSWORD: 'Forgot Password',
  RESET_PASSWORD: 'Reset Password',
  BACK_TO_LOGIN: 'Back to login',
  FORGOT_PASSWORD_LABEL:
    'Enter your email and we will send you instructions to reset your password',
  BUTTON: {
    SIGN_UP: 'Sign Up',
    SIGN_IN: 'Sign In',
    SEND: 'SEND',
    SAVE: 'Save'
  },
  INPUT: {
    FIRSTNAME: 'First Name',
    LASTNAME: 'Last Name',
    EMAIL: 'Email Address',
    GENDER: 'Gender',
    PHONE: 'Phone',
    MALE: 'Male',
    FEMALE: 'Female',
    CREATE_PASSWORD: 'Create Password',
    CONFIRM_PASSWORD: 'Confirm Password',
    PASSWORD: 'Password',
    NEW_PASSWORD: 'New Password'
  }
};

export const ROUTES = {
  HOME: '/',
  REGISTER: '/signup',
  LOGIN: '/signin',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:token',
  DASHBOARD: '/dashboard'
};

// Message-Types
export const MessageType = {
  Info: 'INFO',
  Error: 'ERROR',
  Warn: 'WARN',
  Success: 'SUCCESS'
};

// Gender
export const Gender = {
  Male: 1,
  Female: 2
};

export const StatusCode = {
  BadRequest: 400,
  UnAuthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  AlreadyExists: 409,
  InternalServerError: 500
};

// Role
export const API_REQUEST = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE'
};
