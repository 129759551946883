// Reducers
import auth from './reducers/auth';
import notifications from './reducers/notifications';

// Utility Packages
import { combineReducers } from 'redux';

/* *********************** */
/* ***** Imports End ***** */
/* *********************** */

export default combineReducers({
  auth,
  notifications
});
