// Session
export const setAccessToken = (data) => {
  localStorage.setItem('accessToken', JSON.stringify(data));
};

export const getAccessToken = () => {
  return JSON.parse(localStorage.getItem('accessToken'));
};

export const setLocalStorageUserData = (data) => {
  localStorage.setItem('userData', JSON.stringify(data));
};

export const getLocalStorageUserData = () => {
  return JSON.parse(localStorage.getItem('userData'));
};

export const removeSessionData = () => {
  localStorage.removeItem('userData');
  localStorage.removeItem('accessToken');
  window.location.href = '/';
};
