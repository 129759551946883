import { useEffect, useRef } from 'react';

// Constants
import { ROUTES } from '../constants/general';

// Components
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import Home from '../pages/auth/Home';
import Dashboard from '../pages/dashboard/Dashboard';
import ResetPassword from '../pages/auth/ResetPassword';

// Utility Functions
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

// Utility Packages
import { Toast } from 'primereact/toast';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Store
import { dispatch } from '../utils/store';
import { Actions } from '../redux/actions';
import ForgotPassword from '../pages/auth/ForgotPassword';

export default function Router() {
  const toast = useRef(null);

  useEffect(() => {
    dispatch(Actions.Notifications.SetNotificationsRef, toast);
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <BrowserRouter>
        <Routes>
          <Route
            path={ROUTES.HOME}
            exact
            element={
              <PublicRoute>
                <Home />
              </PublicRoute>
            }
          />
          <Route
            path={ROUTES.REGISTER}
            exact
            element={
              <PublicRoute>
                <Register />
              </PublicRoute>
            }
          />
          <Route
            path={ROUTES.LOGIN}
            exact
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path={ROUTES.FORGOT_PASSWORD}
            exact
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />
          <Route
            path={ROUTES.RESET_PASSWORD}
            exact
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
          <Route
            path={ROUTES.DASHBOARD}
            exact
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}
