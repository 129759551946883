import React, { useState } from 'react';

// APIs
import { LOGIN_API } from '../../constants/configs';

// Constants
import { API_REQUEST, LABELS, ROUTES } from '../../constants/general';

// Components
import Label from '../../components/Label';
import Asteric from '../../components/Asteric';
import CustomPassword from '../../components/CustomPassword';

// Images
import logoImg from '../../assets/images/site-logo.svg';
import logodarkImg from '../../assets/images/site-logo-dark.svg';
import siteLogoTatva from '../../assets/images/site-logo-tatva.svg';
import siteLogoTatvaDark from '../../assets/images/site-logo-tatva-dark.svg';

// Utility Functions
import { loginValidationSchema } from '../../constants/schemas';
import { getFormErrorMessage, isFormFieldValid, trimObjectValues } from '../../utils/general';
import API from '../../utils/api';
import { handleError, handleSuccess } from '../../utils/toast';
import { setAccessToken, setLocalStorageUserData } from '../../utils/session';

// Utility Packages
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { classNames } from 'primereact/utils';

export default function Login() {
  const navigate = useNavigate();

  // State
  const [loading, setLoading] = useState(false);

  // Store
  const toastReff = useSelector((state) => state.notifications.toastReff);

  // Handle form values
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      trimObjectValues(values);
      userLogin(
        {
          email: values.email,
          password: values.password
        },
        resetForm
      );
    }
  });

  const userLogin = (userData, resetForm) => {
    setLoading(true);
    API(API_REQUEST.post, LOGIN_API, userData)
      .then((res) => {
        handleSuccess(toastReff, res);
        // setLocalStorageUserData(res?.data?.data?.userData);
        setAccessToken(res?.data?.data?.data?.token);
        resetForm({ values: '' });
        setLoading(false);
        navigate(ROUTES.DASHBOARD);
      })
      .catch((error) => {
        handleError(toastReff, error);
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <div className="authentication-wrapper">
      <div className="authentication-inner">
        <div className="login-container">
          <span className="site-logo">
            <img src={logoImg} alt="" />
            <img src={logodarkImg} className="dark-theme-logo" alt="" />
          </span>
          <h2>{LABELS.WELCOME}</h2>
          <form className="p-fluid">
            <div className="field">
              <Label htmlFor="email" text={LABELS.INPUT.EMAIL} isBold />
              <Asteric />
              <span className="p-input-icon-right">
                <i className="pi pi-user" />
                <InputText
                  id="email"
                  name="email"
                  placeholder={LABELS.INPUT.EMAIL}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  className={classNames({
                    'p-invalid': isFormFieldValid(formik, 'email')
                  })}
                />
              </span>
              {getFormErrorMessage(formik, 'email')}
            </div>
            <div className="field pwd">
              <CustomPassword
                htmlFor="password"
                text={LABELS.INPUT.PASSWORD}
                ismandatory
                isbold
                id="password"
                name="password"
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                toggleMask
                className={classNames({ 'p-invalid': isFormFieldValid(formik, 'password') })}
                feedback={false}
              />
              {getFormErrorMessage(formik, 'password')}
            </div>
            <div className="link-wrapper space-between">
              <Link to={ROUTES.FORGOT_PASSWORD}>Forgot password?</Link>
            </div>
            <Button className="w-full" onClick={formik.handleSubmit} loading={loading}>
              {LABELS.BUTTON.SIGN_IN}
            </Button>
          </form>
          <div className="tatva-logo-wrap">
            <span>{LABELS.POWERED_BY}</span>
            <img src={siteLogoTatva} alt="Tatvasoft" />
            <img src={siteLogoTatvaDark} alt="Tatvasoft" className="dark-theme-img" />
          </div>
        </div>
      </div>
    </div>
  );
}
