export const Actions = {
  User: {
    SetIsUserLogged: 'SET_USER_IS_LOGGED_IN',
    UserReset: 'USER_RESET'
  },
  Notifications: {
    SetNotificationsRef: 'SET_NOTIFICATIONS_TAOST_REF',
    NotificationsReset: 'NOTIFICATIONS_RESET'
  }
};
