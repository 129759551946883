import * as Yup from 'yup';

// Login
export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .nullable()
    .email('This email is not a valid format.')
    .required('Email is a required field.'),
  password: Yup.string().trim().required('Password is a required field.')
});

// Register
export const registerValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-Z0-9_ ]+$/, 'Firstname can consist of only alphanumeric characters.')
    .required('Firstname is a required field.')
    .min(3, 'Firstname should have minimum 3 characters.')
    .max(100, 'Firstname can have maximum 100 characters.'),
  lastName: Yup.string()
    .matches(/^[a-zA-Z0-9_ ]+$/, 'Lastname can consist of only alphanumeric characters.')
    .required('Lastname is a required field.')
    .min(3, 'Lastname should have minimum 3 characters.')
    .max(100, 'Lastname can have maximum 100 characters.'),
  email: Yup.string()
    .trim()
    .email('This email is not a valid format.')
    .required('Email is a required field.'),
  password: Yup.string()
    .trim()
    .required('Password is a required field.')
    .min(6, 'Password is too short - should be 6 chars minimum.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
      'Password has contain minimum 6 characters (includes 1 Upper, 1 Special, 1 Number).'
    ),
  confirmPassword: Yup.string()
    .trim()
    .required('Confirm password is a required field.')
    .oneOf([Yup.ref('password'), null], 'Password does not match.'),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'Phone must be numeric value.')
    .length(10, 'Phone should have 10 digits.')
    .required('Phone is a required field.')
});

// Forgot Password
export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .nullable()
    .email('This email is not a valid format.')
    .required('Email is a required field.')
});

// Reset Password
export const resetPasswordValidationSchema = Yup.object({
  password: Yup.string()
    .trim()
    .required('Password is a required field.')
    .min(6, 'Password is too short - should be 6 chars minimum.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
      'Password has contain minimum 6 characters (includes 1 Upper, 1 Special, 1 Number).'
    ),
  confirmPassword: Yup.string()
    .required('Confirm Password is required.')
    .oneOf([Yup.ref('password'), null], 'Password does not match.')
});
